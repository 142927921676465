<template>
  <VueDropzone ref="vueDropzone" id="dropzone"
    :options="dropzoneOptionsComputed"
    :awss3="awss3"
    @vdropzone-success="uploadSuccess"
    @vdropzone-s3-upload-error="s3UploadError" />
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {

  name: 'Uploader',

  components: {
    VueDropzone: vue2Dropzone
  },

  props: {

    slug: {
      type: String,
      required: true
    },

    cover: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      dropzoneOptions: {
        url: 'http://chrisandbrittanyjenkins.com/sign/index.php',
        dictDefaultMessage: 'Click or drop images here to add to album',
        thumbnailWidth: 180
      },
      awss3default: {
        signingURL: 'http://chrisandbrittanyjenkins.com/sign/index.php',
        headers: {
          'X-Cbj-Application': 'HNZl5B3lSJaI$7!6ppP3'
        },
        params: {},
        sendFileToServer: false,
        withCredentials: false
      }
    }
  },

  computed: {
    awss3 () {
      return {
        ...this.awss3default,
        params: {
          slug: this.slug,
          cover: this.cover ? 'yes' : 'no'
        }
      }
    },

    dropzoneOptionsComputed () {
      return this.cover ? { ...this.dropzoneOptions, dictDefaultMessage: 'Click or drop cover image here' } : this.dropzoneOptions
    }
  },

  methods: {
    uploadSuccess (file) {
      this.$refs.vueDropzone.removeFile(file)
      this.$emit('uploaded', file.name)
    },

    s3UploadError (errorMessage) {
      alert(errorMessage)
    }
  }
}

</script>

<style lang="postcss" scoped>
  .dropzone {
    @apply bg-transparent flex items-center flex-wrap justify-center overflow-y-auto
  }

  .dropzone /deep/ .dz-preview {
    @apply rounded overflow-hidden
  }

  .dropzone /deep/ > .dz-preview .dz-details {
    @apply bg-gradient-to-b from-indigo-500 to-indigo-600
  }
</style>
